




































import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { AutomationType } from '@/types/automation';
import AvailableAutomations from '@/data/automation_types';
import AutomationInput from '@/components/AutomationInput/index.vue';
import TypeSelector from './TypeSelector.vue';
import AutomationList from './AutomationList.vue';
import MXButton from '@/shared/components/MXButton.vue';

@Component({
  components: {
    AutomationInput,
    TypeSelector,
    AutomationList,
    MXButton,
  },
})
export default class Automations extends Vue {
  @Prop({ type: String }) type!: string;
  @Prop({
    type: Array,
    required: true,
  })
  automations!: any[];

  isAdding = false;
  selectedType: any = null;

  types: AutomationType[] = [];

  mounted() {
    this.types = AvailableAutomations(key => this.$t(key), this.type);
  }

  get title(): TranslateResult {
    return this.$t(`sparkmembers.contents.views.editor.components.${this.type}.components.automations.index.title`);
  }
  get subTitle(): TranslateResult {
    return this.$t(`sparkmembers.contents.views.editor.components.${this.type}.components.automations.index.subtitle`);
  }
  get buttonLabel(): TranslateResult {
    return this.$t(
      `sparkmembers.contents.views.editor.components.${this.type}.components.automations.index.add-button-label`
    );
  }
  get selectorTitle(): TranslateResult {
    return this.$t(
      `sparkmembers.contents.views.editor.components.${this.type}.components.automations.type-selector.title`
    );
  }

  addNewAutomation() {
    if (!this.$FCL.canMembersAutomation()) {
      this.$emit('premium', 'ContentAutomation');
    } else {
      this.isAdding = true;
    }
  }

  automationCanceled() {
    this.isAdding = false;
    this.selectedType = null;
  }
}
