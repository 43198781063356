import { TranslateResult } from 'vue-i18n';
import { AutomationType, AutomationTypeId, AutomationTypeIcon } from '@/types/automation';

export const prefix = 'sparkmembers.contents.views.editor.components';

export default (translate: (key: string) => TranslateResult, productType: string): AutomationType[] => [
  {
    id: AutomationTypeId.EMAIL,
    title: translate(`${prefix}.${productType}.components.automations.type-selector.types.email.name`),
    icon: AutomationTypeIcon.EMAIL,
    active: false,
  },
  {
    id: AutomationTypeId.MESSAGE,
    title: translate(`${prefix}.${productType}.components.automations.type-selector.types.message.name`),
    icon: AutomationTypeIcon.MESSAGE,
    active: false,
  },
  {
    id: AutomationTypeId.TAG,
    title: translate(`${prefix}.${productType}.components.automations.type-selector.types.tag.name`),
    icon: AutomationTypeIcon.TAG,
    active: false,
  },
  {
    id: AutomationTypeId.WEBHOOK,
    title: translate(`${prefix}.${productType}.components.automations.type-selector.types.webhook.name`),
    icon: AutomationTypeIcon.WEBHOOK,
    active: false,
  },
];
