<template>
  <div class="px-3" data-track="lesson content settings">
    <!-- Heading -->
    <div :class="mainClass" class="row p-4 p-md-0">
      <p class="text-center text-md-left">
        {{ title }}
      </p>
    </div>
    <!-- Types -->
    <div class="row justify-content-center mt-md-4">
      <div
        v-for="(type, index) in clonedTypes"
        :key="index"
        class="lesson-option col-12 col-md-2 my-5 d-flex flex-column align-items-center"
      >
        <hs-button-circle
          variant="info-light"
          :icon="type.icon"
          :title="type.title"
          :badge="type.badge"
          :active="type.active"
          :disabled="type.disabled"
          :size="sizeButton"
          @click="typeSelected(type)"
        />
        <!-- Subtypes -->
        <div v-if="type.active" class="d-block d-md-none">
          <div v-for="(subType, index) in type.subTypes" :key="`subType_${index}`" class="mt-3">
            <hs-button-circle
              class="mt-2"
              variant="purple"
              :icon="subType.icon"
              :title="subType.title"
              :badge="subType.badge"
              :disabled="subType.disabled"
              :active="subType.active"
              size="sm"
              @click="subTypeSelected(subType)"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Subtypes -->
    <div class="d-none d-md-flex row justify-content-center" v-if="clonedType">
      <div
        v-for="(subType, index) in activeType.subTypes"
        :key="`subType_${index}`"
        class="col-2 d-flex justify-content-center"
      >
        <hs-button-circle
          variant="info-light"
          :icon="subType.icon"
          :title="subType.title"
          :badge="subType.badge"
          :disabled="subType.disabled"
          :active="subType.active"
          size="sm"
          @click="subTypeSelected(subType)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'TypeSelector',
  data() {
    return {
      clonedTypes: [],
    };
  },
  props: {
    title: {
      type: String,
    },
    isAdditional: {
      type: Boolean,
      default: false,
    },
    types: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.clonedTypes = [...this.types];
  },
  computed: {
    mainClass() {
      return !this.isAdditional ? ['font-weight-bold', 'justify-content-center', 'font-size-md'] : ['font-size-sm'];
    },
    sizeButton() {
      return !this.isAdditional ? 'md' : 'sm';
    },
    ...mapState({
      school: state => state.school.selectedSchool,
    }),
    activeType() {
      return this.clonedTypes.find(t => t.active);
    },
    activeSubtype() {
      const activeType = this.activeType;
      if (activeType && activeType.subTypes) return activeType.subTypes.find(st => st.active);
      return null;
    },
    clonedType() {
      return this.clonedTypes.some(t => t.active);
    },
  },
  methods: {
    typeSelected(type) {
      if (this.activeSubtype) this.activeSubtype.active = false;
      this.clonedTypes = this.clonedTypes.map(t => ({ ...t, active: t.id === type.id }));

      if (!type.features || (type.features && this.$FCL(type.features))) {
        if (!type.hasOwnProperty('subTypes') || (type.subTypes && type.subTypes.length === 0))
          this.$emit('select', type);
      } else {
        this.$emit('premium', type.id);
        this.$emit('select', null);
      }
    },
    subTypeSelected(subType) {
      this.activeType.subTypes.forEach(st => (st.active = st.id === subType.id));

      (subType.features && this.$FCL(subType.features)) || !subType.features
        ? this.$emit('select', subType)
        : this.$emit('premium', subType.id);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.btn-circle-info-light {
  div {
    background-color: #ead3fe !important;
    color: #7427f1 !important;
    transition: all 0.3s ease;

    &:hover {
      background-color: #7427f1 !important;
      opacity: 1 !important;
      color: #fff !important;
    }
  }
}
</style>
